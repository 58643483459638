import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui'

// 全局引入静态资源
import 'element-ui/lib/theme-chalk/index.css'
import './style/main.less'

Vue.use(Element)

Vue.config.productionTip = false;
import './permission.js'
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
