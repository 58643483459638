import axios from 'axios'
import { MessageBox, Message, Notification } from 'element-ui'
import {
  getToken,
  removeToken
} from '@/utils/auth'
import qs from 'qs'

// 创建 Axios
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 15, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // 请求前做些事
    // switch (config.type) {
    //   case 'core': // type1
    //     config.headers['token'] = getHuiCoreToken()
    //     config.url = `${process.env.VUE_APP_HUI_CORE}${config.url}`
    //     break
    //   case 'admin': // type1
    //     config.headers['token'] = getAdminToken()
    //     break
    //   case 'agency': // type1
    //     config.headers['token'] = getAgencyTokenKey()
    //     break
    //   case 'owner': // type1
    //     config.headers['token'] = getOwnerTokenKey()
    //     break
    //   default: // type1
    //     // 设置请求token
    //     config.headers['token'] = getToken()
    //     break
    // }

    // 设置请求token
    config.headers['JUSEID'] = getToken('token')
    if (config.method === 'get') {
      config.url = `${process.env.VUE_APP_BASE_API}/proxy/v1/get`
      config.params = config.data
    }
    if (config.method === 'post') {
      config.url = `${process.env.VUE_APP_BASE_API}/proxy/v1/post`
      config.data = qs.stringify(config.data)
      // // 判断是否是文件上传如果是就不执行数据序列化
      // if (config.isUpload) {
      //   config.headers['Content-Type'] = 'multipart/form-data;'
      // } else {
      //   config.data = qs.stringify(config.data)
      // }
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    // 如果状态码不等于0 就走错误
    if (res.code !== 0) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 10000) {
        // to re-login
        MessageBox.confirm(
          `当前账号已超时，可以取消停留在此页面上，或者再次登录`,
          '温馨提示',
          {
            confirmButtonText: '重新登陆',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(() => {
          // 获取新的token页面重载
          removeToken('token')
          location.reload()
        })
      } else {
        Message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 5 * 1000,
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.msg || '网络连接失败，请稍后重试！',
    //   type: 'error',
    //   duration: 5 * 1000,
    // })
    Notification({
      title: '服务器异常',
      message: '网络连接失败，请稍后重试！',
      type: 'error'
    });
    return Promise.reject(error)
  }
)
export default service
