import request from '@/utils/request'
/**
 * 后台
 */
// 登录
export function login(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysUser/loginByVerifyCode',
      params:JSON.stringify(data)
    }
  })
}
// 无需账号密码
export function loginNoPass(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysUser/demoLogin',
      params:JSON.stringify(data)
    }
  })
}


// 退出登录
export function logout(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysUser/logout',
      params:JSON.stringify(data)
    }
  })
}

// 获取验证码
export function getVerificationCode(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/verifyCode/sendLoginVerifyCode',
      params:JSON.stringify(data)
    }
  })
}
// 获取当前登录用户信息
export function getUserInfo() {
  return request({
    method: 'get',
    data:{
      api:'/sys/sysUser/getCurrentUserInfo'
    }
  })
}
// 获取地区（三级联动）
export function getArea(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/amap/listDistricts',
      params:JSON.stringify(data)
    }
  })
}
// 角色管理-列表
export function getRole() {
  return request({
    method: 'get',
    data:{
      api:'/sys/sysRole/findAll'
    }
  })
}
// 角色管理-新增
export function addRole(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysRole/add',
      params:JSON.stringify(data)
    }
  })
}
// 角色管理-删除
export function delRole(data) {
  return request({
    method: 'get',
    data:{
      api:'/sys/sysRole/delete',
      params:JSON.stringify(data)
    }
  })
}
// 角色管理-重命名
export function renameRole(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysRole/rename',
      params:JSON.stringify(data)
    }
  })
}
// 用户管理-用户列表
export function getUser(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysUser/findUsers',
      params:JSON.stringify(data)
    }
  })
}
// 用户管理-添加用户
export function addUser(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysUser/add',
      params:JSON.stringify(data)
    }
  })
}
// 用户管理-修改用户
export function editUser(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysUser/modify',
      params:JSON.stringify(data)
    }
  })
}
// 用户管理-启用/禁用用户
export function disableUser(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/sysUser/disableSysUser',
      params:JSON.stringify(data)
    }
  })
}
// 商户管理-列表
export function getMerchan(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/merchant/find',
      params:JSON.stringify(data)
    }
  })
}
// 商户管理-新增
export function addMerchan(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/merchant/create',
      params:JSON.stringify(data)
    }
  })
}
// 商户管理-编辑
export function editMerchan(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/merchant/modify',
      params:JSON.stringify(data)
    }
  })
}
// 商户管理-删除
export function delMerchan(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/merchant/delete',
      params:JSON.stringify(data)
    }
  })
}
// 商户管理-详情
export function detailMerchan(data) {
  return request({
    method: 'get',
    data:{
      api:'/sys/merchant/get',
      params:JSON.stringify(data)
    }
  })
}
// 运维管理-列表
export function getOperations(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/oamp/find',
      params:JSON.stringify(data)
    }
  })
}
// 运维管理-新增
export function addOperations(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/oamp/create',
      params:JSON.stringify(data)
    }
  })
}
// 运维管理-编辑
export function editOperations(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/oamp/modify',
      params:JSON.stringify(data)
    }
  })
}
// 运维管理-删除
// export function delOperations(data) {
//   return request({
//     method: 'post',
//     data:{
//       api:'/sys/merchant/delete',
//       params:JSON.stringify(data)
//     }
//   })
// }
// 运维管理-详情
export function detailOperations(data) {
  return request({
    method: 'get',
    data:{
      api:'/sys/oamp/get',
      params:JSON.stringify(data)
    }
  })
}
// 订单管理-列表
export function getOrder(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/printOrder/find',
      params:JSON.stringify(data)
    }
  })
}
// 设备管理-列表
export function getDevice(data) {
  return request({
    method: 'post',
    data:{
      api:'/sys/terminalDevice/find',
      params:JSON.stringify(data)
    }
  })
}
// 设备管理-重命名
export function renameDevice(data) {
  return request({
    method: 'post',
    data: {
      api: '/sys/terminalDevice/renameDevice',
      params: JSON.stringify(data)
    }
  })
}
// 投放计划-列表
export function getPlan(data) {
  return request({
    method: 'post',
    data: {
      api: '/sys/launchPlan/findPlans',
      params: JSON.stringify(data)
    }
  })
}
// 投放计划-新增/修改
export function savePlan(data) {
  return request({
    method: 'post',
    data: {
      api: '/sys/launchPlan/savePlan',
      params: JSON.stringify(data)
    }
  })
}
// 投放计划-审核
export function checkPlan(data) {
  return request({
    method: 'post',
    data: {
      api: '/sys/launchPlan/auditPlan',
      params: JSON.stringify(data)
    }
  })
}
// 投放计划-绑定设备
export function bindingPlan(data) {
  return request({
    method: 'post',
    data: {
      api: '/sys/launchPlan/bindDevice',
      params: JSON.stringify(data)
    }
  })
}
// 投放计划-删除设备
export function delBind(data) {
  return request({
    method: 'post',
    data: {
      api: '/sys/launchPlan/unbindDevice',
      params: JSON.stringify(data)
    }
  })
}
// 投放计划-绑定设备列表
export function bindListPlan(data) {
  return request({
    method: 'post',
    data: {
      api: '/sys/launchPlan/listDevices',
      params: JSON.stringify(data)
    }
  })
}