import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { getUserInfo } from '@/api/admin'
export default new Vuex.Store({
  state: {
    // 侧边菜单放大缩小
    isCollapse:false,
    // tags当前索引值
    tagIndex:-1,
    // 当前用户信息
    userInfo:null
  },
  mutations: {
    SET_IS_COLLAPSE(state){
      state.isCollapse = !state.isCollapse
    },
    SET_TAG_INDEX(state,index){
      state.tagIndex = index
    },
    SET_USER_INFO(state,data){
      state.userInfo = data
    }
  },
  actions: {
    // 获取userinfo
    loadUserInfo({commit}){
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(({ data }) => {
            commit('SET_USER_INFO',data)
            resolve(data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
  modules: {},
});
