import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect:'index',
  },
  {
    path: "/index",
    name: "index",
    component: () =>
      import("../views/index"),
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () =>
      import("../views/introduction"),
  },
  {
    path: "/recruitment",
    name: "recruitment",
    component: () =>
      import("../views/recruitment"),
  },
  {
    path: "/recruitment_detail",
    name: "recruitment",
    component: () =>
      import("../views/recruitment/detail"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import("../views/about"),
  },
  {
    path: "/app_download",
    name: "app_download",
    component: () =>
      import("../views/appDownload"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
